import axios from "config/axios";
import { GenericListResponse, GenericResponse, Vehicle } from "shared/types";

export type VehicleTourInfo = {
  id: number | null;
  vehicle: Partial<Vehicle>;
  deliveryDate: string; //1970-01-01
  tourLength: number;
  link: string;
  createdBy: number;
  updatedBy: number;
  isDelete: boolean;
};

const ENDPOINT = "/vehicle-info";

export const saveVehicleInfo = (params: Partial<VehicleTourInfo>[]) =>
  axios.post<GenericResponse<VehicleTourInfo>>(`${ENDPOINT}/save`, { params });

export const listVehicleInfo = (date: string) =>
  axios.get<GenericListResponse<VehicleTourInfo>>(`${ENDPOINT}/list-by-date?date=${date}`);
