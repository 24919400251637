import { MatrixType } from "./network/deliveryprice.api";
import { VehicleTourInfo } from "./network/vehicle-info.api";

/**
 * More types for Typescript
 */
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type ResponseStatus =
  | "OK"
  | "INVALID_INPUT"
  | "ERROR"
  | "NOT_FOUND"
  | "CREATED"
  | "CONFLICT"
  | "FORBIDDEN"
  | "DEPENDENT"
  | "DUPLICATED"
  | "BAD_FILE";

export enum SliceStatus {
  Idle = "idle",
  Loading = "loading",
  Error = "error",
  Success = "success",
}

export type HttpResponse = {
  status: ResponseStatus | string;
};

export type QueryString = {
  [key in string | number]: any;
};

export type Pageable = {
  pageSize: number;
  pageNumber: number;
  last: boolean;
  predicates: string | null;
  totalElements: number;
};

export type GenericResponse<T> = {
  item: T;
} & HttpResponse;

export type GenericListResponse<T> = {
  items: T[];
} & HttpResponse;

export type GenericMapResponse<T, D> = {
  items: Map<T, D>;
} & HttpResponse;

export type GenericPageResponse<T> = {
  page: Page<T>;
} & HttpResponse;

export type GenericRequest<T> = {
  param: T;
};

export type Map<T, D> = {
  items: [T, D];
};

export type Page<T> = {
  content: T[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  size: number;
  number: number;
  empty: boolean;
};

export type AuthenticationResponse = {
  id_token: string;
  status: string;
};

export type Property = {
  id: number;
  editable: boolean;
  name: string;
  value: string;
  moduleName: string;
  propertyType: string;
};

export type UserPermission = {
  companyId: number;
  userCompanyPermissions: string[];
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  token?: string;
  status: string;
  userType: string;
  phone: string;
  identityCardNumber: string;
  addressCard: string;
  birthDate: string;
  permanentAddressId: number;
  permanentAddress: Address;
  temporaryAddressId: number;
  temporaryAddress: Address;
  postAddressId: number;
  postAddress: Address;
  isAdmin: boolean;
  isCustomerService: boolean;
  validityOfMedicalFitness: string;
  relUserCompanies: UserCompany[];
};

export type UserCompany = {
  id: number;
  userId: number;
  companyId: number;
  isShipOrganizer: boolean;
  isShipper: boolean;
  isShipperAdmin: boolean;
  isShopkeeper: boolean;
  isWarehouseKeeper: boolean;
  employeeNumber: string;
  licenseCategory: string;
  licenseExpirationDate: string;
  licenseNumber: string;
  isPickupPointUser: boolean;
};

export type DeliveryRequest = {
  id: number;
  companyId: number;
  withLoaderExtraCharge: number;
  hazardousExtraChargePercent: number;
  fragileExtraCharge: number;
  cashOnDeliveryMinimumPrice: number;
  cashOnDeliveryPercent: number;
  expressDeliveryPrice: number;
  uniqueInsurancePercent: number;
  authorityToLeavePercent: number;
  weekendDelivery: number;
  weekendDeliveryWithLoader: number;
  storagePriceDaily: number;
  freeStorageDays: number;
  electricCarExtraPercent: number;
  whiteGoods: number;
  removalBathroom: number;
  removalMattress: number;
  removalFurniture: number;
  removalSofas: number;
  removalKitchen: number;
  removalElectronics: number;
  removalAdditional: number;
};

export type Company = {
  id: number;
  postAddress: Address;
  billingAddress: Address;
  name: string;
  taxNumber: string;
  registrationNumber: string;
  email: string;
  phone: string;
  bankaccount: string;
  description: string;
  isShipper: boolean;
  isWarehouse: boolean;
  isVolumetricWeight: boolean;
  volumetricWeightVariable: number;
  isShopKeeper: boolean;
  isDefaultDeliveryToWarehouse: boolean;
  deliveryExtraChargeEntry: DeliveryRequest;
  rangeOfDays: number;
};

export type CompanyRequest = {
  company: Partial<Company>;
  shop?: Partial<Shop>;
  shopAddressList?: Partial<Address[]>;
  isDefaultDeliveryToWarehouse?: boolean;
};

export type Address = {
  id: number;
  country: string;
  title: string;
  zipCode: number;
  city: string;
  areaName: string;
  areaType: string;
  houseNumber: string;
  door: string | null;
  floor: string | null;
  latitude: number;
  longitude: number;
  createdBy: number;
  updatedBy: number;
};

export type ForgottenPassword = {
  newPassword: string;
  newPassword2: string;
  token: string;
};

export type AuditLog = {
  id: number;
  userId: number;
  userName: string;
  message: string;
  type: string;
  status: string;
  description: string;
  companyId: number;
  createdBy: number;
  updatedBy: number;
  createdOn: string;
  updatedOn: string;
};

export type PaginationType = {
  page: number;
  size: number;
  totalPages: number;
  totalElements: number;
};

export type UserRegistrationRequest = {
  user: DeepPartial<User> & {
    birthday?: {
      year: number;
      month: number;
      day: number;
    };
  };
};

export type UserModifyRequest = {
  param: DeepPartial<User> & {
    birthday?: {
      year: number;
      month: number;
      day: number;
    };
  };
};

export type Article = {
  id: number;
  title: string;
  content: string;
  isActive: boolean;
  subjectType: ArticleSubjectType;
};

export type ArticleSubjectType = "GDPR" | "TOS" | string;

export type Package = {
  plannedToVehicle: string;
  plannedDate: string;
  alternateAddress: Address;
  isPUP: boolean;
  id: number;
  currentAddress: Address;
  sourceAddress: Address;
  sourceCountry: string;
  sourceCity: string;
  sourceStreetAddress: string;
  sourceLongitude: number;
  sourceLatitude: number;
  sourceZipCode: number;
  sourceDateFrom?: string;
  sourceDateTo?: string;
  targetAddress: Address;
  targetCity: string;
  targetStreetAddress: string;
  targetLongitude: number;
  targetLatitude: number;
  targetZipCode: number;
  targetDateFrom?: string;
  targetDateTo?: string;
  customerBillingAddress: Address;
  shopAddressList: Address[];
  statuses: PackageStatus[];
  shop: Shop;
  actualStatus: PackageStatus;
  targetCountry: string;
  pdfPath: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  customerComment: string;
  weight: number;
  volume: number;
  height: number;
  width: number;
  length: number;
  trackingNumber: string;
  deliveryPriceToWebshop?: number;
  vatPercent?: number;
  shopPackagesNumber: string;
  value: number;
  isAskLoader: boolean;
  isHazardous: boolean;
  isFragile: boolean;
  isStackable: boolean;
  isUniqueInsurance: boolean;
  isCashOnDelivery: boolean;
  authorityToLeave: boolean;
  failedDelivery: boolean;
  isDeliveryToWarehouse: boolean;
  isExpressDelivery: boolean;
  plannedToTour: boolean;
  markedToReturn: boolean;
  isPackageChange: boolean;
  isReturnPackage1: boolean;
  isReturnPackage2: boolean;
  isOneLoader: boolean;
  isPup: boolean;
  paymentDeliveryType: string;
  name: string;
  sameTargetAddressWithBilling: boolean;
  /* TODO: backenden meg kell meg tervezni
  sourceAddressId: Id;
  targetAddressId: Id; */
  labelPdfPath: string;
  deliveryNote: string;
  deliveryNoteSigned: string;
  updatedOn: string;
  shippingPrices: ShippingPrice[];
  shipment: Shipment;
  returnGoods: ReturnGoods[];
  cdunumber: string;
  targetDateModifiedStatus: string;
};

export type ReturnGoods = {
  id?: number;
  packageId?: number;
  type: string;
  quantity: number;
};

export type PackageStatus = {
  id: number;
  storageId: number;
  packagesId: number;
  status: string;
  longitude: number;
  latitude: number;
  localDate: string;
  vehicleId: number;
  storage: Storage;
  createdOn: string;
  createdBy: User;
  relPackagesStatusStorages: {
    storage: Storage;
  }[];
  updatedOn: string;
};

export type PackageResponse = {
  packagesList: DeepPartial<Package>[];
  companyId?: number;
  sourceAddress?: Address;
};

export type PackageModifyResponse = {
  packages: DeepPartial<Package>;
  companyId: number;
};

export type Shop = {
  id: number;
  companyId?: number;
  company?: Company;
  webpage: string;
  ip: string;
  prefix: string;
  comment: string;
  apiKey?: string;
};

export type Warehouse = {
  id: number;
  companyId: number;
  address: Address;
  name: string;
  space: number;
  height: number;
};

export type Storage = {
  id: number;
  name: string;
  storageCode: string;
  warehouseId: number;
};

export type StorageType = "Palette" | "Shelf";

export type Vehicle = {
  vehicleInfo: VehicleTourInfo;
  status: string;
  phone: string;
  id?: number;
  company: Partial<Company>;
  user?: Partial<User>;
  weightCapacity: number;
  volume: number;
  licensePlateNumber: string;
  brand: string;
  type: string;
  environmentalCategory: string;
  axleNumber: number;
  firstRegisteredDate: string;
  comerenceDescription: string;
  chassisNumber: string;
  allWeight: number;
  selfWeight: number;
  carDocumentsExpirationDate: string;
  registeredDateInHungary: string;
  tachographExpirationDate: string;
  typeApproveNumber: string;
  cylinderCapacity: number;
  maxPerformance: number;
  propellant: string;
  performanceWeightRatio: number;
  seatNumber: number;
  standingPlaceNumber: number;
  vehicleCategory: string;
  haulData: string;
  motorCode: string;
  vehicleColor: string;
  colEncumbrance: number;
  euroPaletteCapacity: number;
  eurotaxCode: string;
  gearboxType: string;
  tailLift: boolean;
  height: number;
  width: number;
  length: number;
  productionYear: number;
  bodyType: string;
  co2Amount: string;
};

export type Note = {
  filePath: string | null;
  id: number;
  latitude: number | null;
  longitude: number | null;
  note: string;
  subjectId: number;
  subjectType: string;
  type: string;
  createdBy: number;
  createdOn: string;
  user: User | null;
};

export type Contact = {
  id: number;
  companyId: number;
  name: string;
  email: string;
  phone: string;
  comment: string;
};

export type ShippingPrice = {
  id: number;
  packagesStatus: PackageStatus;
  packages: Package;
  vehicle: Vehicle;
  netPrice: number;
};

export type GpsCoordinate = {
  createdBy: number;
  dateTime: string;
  id: number;
  latitude: number;
  longitude: number;
  updatedBy: number;
  vehicleId: number;
};

export type RelZipZoneEntry = {
  id?: number;
  companyId: string;
  zoneId: string;
  zipFrom: string;
  zipTo: string;
  isActive?: boolean;
  type: MatrixType;
};

export type Shipment = {
  id: number;
  companyId: number;
  shipmentType: string; // IKEA shipment Type LCD CCD
  deliveryCode: string; // IKEA delivery code
  trackingNumber: string;
  orderShopNumber: string; //(isell number) EDI: orderNumber
  orderExternalNumber: string; //(centiro number) EDI: shipmentNumber
  isExchange: boolean; //kell e felvenni a lerakásnál másik csomagot
  uploaderType: string;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  updatedBy: number;
  returnGoods?: ReturnGoods[];
};
