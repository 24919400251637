import { CircularProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { endOfWeek, format, isBefore, isValid, startOfWeek, subWeeks } from "date-fns";
import { hu } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { exportTourByInterval } from "shared/network/tour.api";
import fileDownload from "shared/util/fileDownload";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  date: Date;
};

type FormValues = {
  fromDate: Date;
  toDate: Date;
};

const IntervalExportDialog = ({ open, setOpen, date }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>();
  const { register, setValue } = form;
  const fromDate = form.watch("fromDate");
  const toDate = form.watch("toDate");
  const invalidForm = !isValid(toDate) || !isValid(fromDate) || isBefore(toDate, fromDate);

  useEffect(() => {
    console.log(open);
    if (open) {
      register("fromDate");
      setValue("fromDate", subWeeks(startOfWeek(new Date(), { locale: hu }), 1));
      register("toDate");
      setValue("toDate", endOfWeek(new Date(), { locale: hu }));
    }
  }, [open, register, setValue]);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (values.fromDate && values.toDate) {
        const { data } = await exportTourByInterval({
          startDate: format(values.fromDate, "yyyy-MM-dd"),
          endDate: format(values.toDate, "yyyy-MM-dd"),
        });

        fileDownload(
          data,
          `tours_${format(values.fromDate, "yyyy-MM-dd")}_${format(
            values.toDate,
            "yyyy-MM-dd",
          )}.xlsx`,
        );
        enqueueSnackbar(t("tour.exportSuccess"), {
          variant: "success",
        });
        setOpen(false);
      }
    } catch (error: any) {
      enqueueSnackbar(t("tour.exportError"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("tour.tourExport")}</DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="fromDate"
              defaultValue={subWeeks(startOfWeek(new Date(), { locale: hu }), 1)}
              render={({ ref, ...props }) => (
                <KeyboardDatePicker
                  {...props}
                  format="yyyy. MM. dd."
                  label={t("tour.fromDate")}
                  autoOk
                />
              )}
            />
            <Controller
              name="toDate"
              defaultValue={endOfWeek(new Date(), { locale: hu })}
              render={({ ref, ...props }) => (
                <KeyboardDatePicker
                  {...props}
                  format="yyyy. MM. dd."
                  label={t("tour.toDate")}
                  autoOk
                />
              )}
            />
            {invalidForm && (
              <Typography
                style={{ fontWeight: "bold", color: "red", textAlign: "center", paddingTop: 8 }}
              >
                Adj meg egy helyes időintervallumot!
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>{t("common:button.cancel")}</Button>
            <Button
              variant="contained"
              disabled={loading || invalidForm}
              color="primary"
              type="submit"
            >
              {loading ? <CircularProgress size={24} /> : t("common:button.ok")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default IntervalExportDialog;
