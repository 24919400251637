import { Box, Grid, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Dispatch, SetStateAction } from "react";
import { ArrayField, Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Vehicle } from "shared/types";

export type Props = {
  field: Partial<ArrayField<Record<string, any>, "key">>;
  index: number;
  remove: (index?: number | number[]) => void;
  setRemoved: Dispatch<SetStateAction<any[]>>;
  vehicles: Vehicle[];
  isChange: any;
  setIsChange: any;
};

const VehicleInfoDialogRow = ({ field, index, remove, setRemoved, vehicles }: Props) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { register, errors, watch } = form;
  const rows = watch("rows");

  return (
    <Box key={field.key} display="flex" alignItems="center" gridGap={8} paddingBottom={2}>
      <Typography style={{ fontWeight: "bold" }}>{index + 1}.</Typography>
      <Grid container spacing={1}>
        <input
          name={`rows.${index}.id`}
          value={field.id || ""}
          type="hidden"
          ref={form.register()}
        />
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={`rows.${index}.vehicleId`}
            defaultValue={field.vehicleId || ""}
            rules={{
              required: {
                value: true,
                message: t("validation:required"),
              },
            }}
            render={props => (
              <TextField
                {...props}
                onChange={event => {
                  props.onChange(event.target.value);
                }}
                margin="none"
                label={t("package.vehicle")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!errors.rows?.[index]?.vehicleId}
                helperText={errors.rows?.[index]?.vehicleId?.message}
                SelectProps={{ displayEmpty: true }}
                select
              >
                <MenuItem value="">
                  <em>{t("common:choose")}</em>
                </MenuItem>
                {vehicles.map(option => (
                  <MenuItem
                    key={option?.id}
                    value={option?.id}
                    disabled={
                      rows?.find((row: any) => row?.vehicleId === option?.id) &&
                      option?.id !== field?.vehicleId
                    }
                  >
                    {option.licensePlateNumber}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <TextField
            margin="none"
            name={`rows.${index}.link`}
            defaultValue={field.link || ""}
            label={t("vehicleInfo.link")}
            InputLabelProps={{ shrink: true }}
            inputRef={register({
              required: {
                value: false,
                message: t("validation:required"),
              },
            })}
            error={!!errors.rows?.[index]?.link}
            helperText={errors.rows?.[index]?.link?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            margin="none"
            type="number"
            name={`rows.${index}.tourLength`}
            defaultValue={field.tourLength || ""}
            label={t("vehicleInfo.tourLength")}
            InputLabelProps={{ shrink: true }}
            inputRef={register({
              required: {
                value: false,
                message: t("validation:required"),
              },
            })}
            error={!!errors.rows?.[index]?.tourLength}
            helperText={errors.rows?.[index]?.tourLength?.message}
          />
        </Grid>
      </Grid>
      <IconButton
        size="small"
        onClick={() => {
          remove(index);
          if (field.id) {
            setRemoved(removed => {
              return [...removed, { ...field, isDelete: true }];
            });
          }
        }}
      >
        <Delete />
      </IconButton>
    </Box>
  );
};

export default VehicleInfoDialogRow;
