import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ColumnType } from "./PackageList";
import { useTranslation } from "react-i18next";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import Alert from "@material-ui/lab/Alert";

type Props = {
  open: boolean;
  columns: ColumnType;
  setColumns: Dispatch<SetStateAction<ColumnType>>;
  setColumnOpen: Dispatch<SetStateAction<boolean>>;
  columnNumber?: number;
  type: "packagelist" | "tour" | "warehouse";
};

const PackageList = ({ open, columns, setColumns, setColumnOpen, columnNumber, type }: Props) => {
  const { t } = useTranslation();

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setColumns(prevState => {
      localStorage.setItem(
        `${type}-package-columns`,
        JSON.stringify({
          ...prevState,
          [event.target.name]: !prevState[event.target.name],
        }),
      );
      return {
        ...prevState,
        [event.target.name]: !prevState[event.target.name],
      };
    });
  }

  return (
    <Dialog open={open} onClose={() => setColumnOpen(false)}>
      <DialogTitle>Oszlopok kiválasztása</DialogTitle>
      {!!columnNumber && <Alert severity="info">{t("tour.columnAlert")}</Alert>}
      <DialogContent>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.status)}
                checked={!!columns.status}
                onChange={onChange}
                name="status"
                color="primary"
              />
            }
            label={t("package.status.title")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.packageName)}
                checked={!!columns.packageName}
                onChange={onChange}
                name="packageName"
                color="primary"
              />
            }
            label={t("tour.packageName")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.trackingNumber)}
                checked={!!columns.trackingNumber}
                onChange={onChange}
                name="trackingNumber"
                color="primary"
              />
            }
            label={t("packageFilters.trackingNumber")}
          />
        </Box>
        {type !== "tour" && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!!(columnNumber && columnNumber >= 8 && !columns.plannedToTour)}
                  checked={!!columns.plannedToTour}
                  onChange={onChange}
                  name="plannedToTour"
                  color="primary"
                />
              }
              label={t("packageFilters.plannedToTour")}
            />
          </Box>
        )}
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.cdunumber)}
                checked={!!columns.cdunumber}
                onChange={onChange}
                name="cdunumber"
                color="primary"
              />
            }
            label={t("packageFilters.cdunumber")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.orderShopNumber)}
                checked={!!columns.orderShopNumber}
                onChange={onChange}
                name="orderShopNumber"
                color="primary"
              />
            }
            label={t("packageFilters.orderShopNumber")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.orderExternalNumber)}
                checked={!!columns.orderExternalNumber}
                onChange={onChange}
                name="orderExternalNumber"
                color="primary"
              />
            }
            label={t("packageFilters.orderExternalNumber")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.volume)}
                checked={!!columns.volume}
                onChange={onChange}
                name="volume"
                color="primary"
              />
            }
            label={t("tour.volume")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.storageCode)}
                checked={!!columns.storageCode}
                onChange={onChange}
                name="storageCode"
                color="primary"
              />
            }
            label={t("package.storageCode")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.weight)}
                checked={!!columns.weight}
                onChange={onChange}
                name="weight"
                color="primary"
              />
            }
            label={t("tour.weight")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.icons)}
                checked={!!columns.icons}
                onChange={onChange}
                name="icons"
                color="primary"
              />
            }
            label={t("package.prop")}
          />
        </Box>
        {/*<Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  !!(columnNumber && columnNumber >= 8 && !columns.returnGoods)
                }
                checked={!!columns.returnGoods}
                onChange={onChange}
                name="returnGoods"
                color="primary"
              />
            }
            label={t("package.returnGoods.title")}
          />
          </Box>*/}
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.companyName)}
                checked={!!columns.companyName}
                onChange={onChange}
                name="companyName"
                color="primary"
              />
            }
            label={t("tour.companyName")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.customerName)}
                checked={!!columns.customerName}
                onChange={onChange}
                name="customerName"
                color="primary"
              />
            }
            label={t("packageFilters.customerName")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.customerEmail)}
                checked={!!columns.customerEmail}
                onChange={onChange}
                name="customerEmail"
                color="primary"
              />
            }
            label={t("package.properties.customerEmail")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.customerPhone)}
                checked={!!columns.customerPhone}
                onChange={onChange}
                name="customerPhone"
                color="primary"
              />
            }
            label={t("package.properties.customerPhone")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.customerComment)}
                checked={!!columns.customerComment}
                onChange={onChange}
                name="customerComment"
                color="primary"
              />
            }
            label={t("package.properties.customerComment")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.currentAddress)}
                checked={!!columns.currentAddress}
                onChange={onChange}
                name="currentAddress"
                color="primary"
              />
            }
            label={t("tour.currentAddress")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.targetAddress)}
                checked={!!columns.targetAddress}
                onChange={onChange}
                name="targetAddress"
                color="primary"
              />
            }
            label={t("tour.targetAddress")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.sourceAddress)}
                checked={!!columns.sourceAddress}
                onChange={onChange}
                name="sourceAddress"
                color="primary"
              />
            }
            label={t("tour.sourceAddress")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.targetFromDate)}
                checked={!!columns.targetFromDate}
                onChange={onChange}
                name="targetFromDate"
                color="primary"
              />
            }
            label={t("tour.targetFromDate")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.targetToDate)}
                checked={!!columns.targetToDate}
                onChange={onChange}
                name="targetToDate"
                color="primary"
              />
            }
            label={t("tour.targetToDate")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.sourceFromDate)}
                checked={!!columns.sourceFromDate}
                onChange={onChange}
                name="sourceFromDate"
                color="primary"
              />
            }
            label={t("tour.sourceFromDate")}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!(columnNumber && columnNumber >= 8 && !columns.sourceToDate)}
                checked={!!columns.sourceToDate}
                onChange={onChange}
                name="sourceToDate"
                color="primary"
              />
            }
            label={t("tour.sourceToDate")}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="primary" onClick={() => setColumnOpen(false)}>
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PackageList;
