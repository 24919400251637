import { MenuItem, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { AxiosResponse } from "axios";
import { palette } from "config/theme";
import { compareAsc, format } from "date-fns";
import { hu } from "date-fns/locale";
import { trim } from "lodash";
import WhiteIcon from "pictures/white-logo.svg";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer } from "react-leaflet";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router";
import { packageTracking } from "shared/network/package.api";
import { Package } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import TrackingMapMarker from "./components/TrackingMapMarker";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Tracking = ({
  match,
}: RouteComponentProps<{
  trackingNumber: string;
}>) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>(undefined);
  const [validated, setValidated] = useState(false);

  const trackingQuery = useQuery<Package[], AxiosResponse>(
    "trackingQuery",
    async () => {
      const { data } = await packageTracking(trim(match.params.trackingNumber));
      return data.items;
    },
    { enabled: validated },
  );
  const error = trackingQuery?.error?.data.status;

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("submit");

    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    setSelectedPackage(
      trackingQuery.data?.find(entry => entry.trackingNumber === match.params.trackingNumber),
    );
  }, [trackingQuery.data, setSelectedPackage, match.params.trackingNumber]);

  useEffect(() => {
    handleReCaptchaVerify().then(async value => {
      if (value) {
        setValidated(true);
      }
    });
  }, [handleReCaptchaVerify]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={{
          backgroundColor: palette.main,
          height: 64,
          position: "fixed",
          width: "100%",
        }}
      >
        <img src={WhiteIcon} alt="" style={{ width: 36, height: 36, marginLeft: 16 }} />
        <Typography
          variant="h6"
          noWrap
          style={{
            color: "#fff",
            marginLeft: 16,
            fontFamily: "'Montserrat Alternates', sans-serif",
            fontWeight: "bold",
          }}
        >
          {t("common:appName")}
        </Typography>
        <Divider orientation="vertical" flexItem style={{ backgroundColor: "white", margin: 12 }} />
        <Typography
          variant="h6"
          noWrap
          style={{
            color: "#fff",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "bold",
          }}
        >
          {selectedPackage?.name ? `Csomag követése: ${selectedPackage?.name}` : "Csomag követése"}
        </Typography>
      </Box>
      {error === "NOT_FOUND" ? (
        <Container
          maxWidth="lg"
          style={{ paddingTop: 128, display: "flex", justifyContent: "center" }}
        >
          <Typography variant="h6">{t("package.tracking.notFound")}</Typography>
        </Container>
      ) : error ? (
        <Container
          maxWidth="lg"
          style={{ paddingTop: 128, display: "flex", justifyContent: "center" }}
        >
          <Typography variant="h6">{t("package.tracking.error")}</Typography>
        </Container>
      ) : (
        <Container maxWidth="lg" style={{ paddingTop: 64 }}>
          <Box display="flex" p={2}>
            <Box display="flex" alignItems="center" gridGap={18}></Box>
          </Box>
          {/* <Layout title={`Csomag követése: ${pack?.name}`} maxWidth="xl"> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="space-around" style={{ marginBottom: 16 }}>
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.PAYMENT_MODE")}
                  </Typography>
                  <Typography>
                    {selectedPackage?.isCashOnDelivery ? "Utánvét," : "Fizetve,"}{" "}
                    {selectedPackage?.value}
                    Ft
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>Bolt</Typography>
                  <Box display="flex">
                    <Typography style={{ marginRight: 12 }}>
                      {selectedPackage?.shop?.company?.name}
                    </Typography>
                    {selectedPackage?.shop?.webpage.startsWith("https://") ||
                    selectedPackage?.shop?.webpage.startsWith("http://") ? (
                      <a
                        href={selectedPackage?.shop?.webpage ?? ""}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography>{selectedPackage?.shop?.webpage}</Typography>
                      </a>
                    ) : (
                      <a
                        href={"https://" + selectedPackage?.shop?.webpage}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography>{selectedPackage?.shop?.webpage}</Typography>
                      </a>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.targetAddress")}:
                  </Typography>
                  <Typography>{displayAddress(selectedPackage?.targetAddress)}</Typography>
                </Box>
              </Box>
              <MapContainer
                center={[
                  selectedPackage?.actualStatus?.latitude || 47,
                  selectedPackage?.actualStatus?.longitude || 19.5,
                ]}
                zoom={7}
                style={{ height: "75vh" }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {selectedPackage?.statuses?.map((status, index) => (
                  <TrackingMapMarker key={status.id} number={index + 1} status={status} />
                ))}
              </MapContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" margin="0 24px" overflow="auto">
                <Box display="flex" width="100%" justifyContent="center">
                  <Box width="250px" marginLeft="10px" height="73px">
                    <TextField
                      name="companyId"
                      label={t("package.trackingNumber")}
                      value={selectedPackage?.id || ""}
                      onChange={e => {
                        let id = Number.parseInt(e.target.value);
                        setSelectedPackage(trackingQuery.data?.find(entry => entry.id === id));
                      }}
                      select
                    >
                      <MenuItem value="" disabled>
                        <em>{t("common:choose")}</em>
                      </MenuItem>
                      {trackingQuery.data?.map((pack, index) => (
                        <MenuItem key={index} value={pack.id}>
                          {pack.trackingNumber}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>
                <Divider style={{ width: "100%", margin: "16px 0" }} />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.tracking.currentState")}:
                  </Typography>
                  <Typography>
                    {t(`package.status.${selectedPackage?.actualStatus?.status}`)}
                  </Typography>
                  <Typography> {displayAddress(selectedPackage?.currentAddress)}</Typography>
                </Box>
                {selectedPackage?.statuses
                  .sort((a, b) => {
                    return compareAsc(new Date(a.createdOn), new Date(b.createdOn));
                  })
                  .map((status, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        margin="8px 0"
                      >
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <div
                            style={{
                              minHeight: 50,
                              minWidth: 50,
                              color: "white",
                              backgroundColor: palette.orange,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                              fontWeight: "bold",
                              fontSize: "18px",
                              marginRight: 12,
                            }}
                          >
                            {index + 1}
                          </div>
                          <Typography style={{ fontWeight: "bold" }}>
                            {t(`package.status.${status.status}`)}
                          </Typography>
                        </Box>
                        <Typography>
                          {`${format(new Date(status.updatedOn), "Pp", {
                            locale: hu,
                          })}`}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Tracking;
