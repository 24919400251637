import { faPeopleCarry, faShippingFast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {
  BrokenImage,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  DescriptionOutlined,
  Height,
  Warning,
} from "@material-ui/icons";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import CODIcon from "components/package/money.png";
import { ColumnType } from "components/package/PackageList";
import { palette } from "config/theme";
import { format, isAfter, isToday, startOfDay } from "date-fns";
import { round } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Package } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { TourPlan } from "../Tour";

type Props = {
  date: Date;
  pack: Package;
  columns: ColumnType;
  setWarningData: Dispatch<SetStateAction<any>>;
  setAddressData: Dispatch<SetStateAction<any>>;
  enableMultiSelect: boolean;
  selectedPackageList?: Package[];
  addPackage?: (pack: Package) => void;
  removePackage?: (pack: Package) => void;
};

const useStyles = makeStyles(
  () => ({
    drag: {
      cursor: "grab",
      "& :active": {
        cursor: "grabbing",
      },
    },
    iconData: {
      display: "flex",
      alignItems: "center",
      gridGap: 8,
      fontSize: 16,
      marginLeft: 10,
    },
  }),

  { name: "PackageListItem" },
);

const PackageListItem = ({
  pack,
  date,
  columns,
  setWarningData,
  setAddressData,
  enableMultiSelect,
  selectedPackageList,
  addPackage,
  removePackage,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const haveDates = !!(
    pack.sourceDateFrom &&
    pack.sourceDateTo &&
    pack.targetDateFrom &&
    pack.targetDateTo
  );

  const dragEnabled =
    (isAfter(startOfDay(date), new Date()) || isToday(date)) && !enableMultiSelect;

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: "box",
    item: { package: pack },
    end: async (item, monitor) => {
      const dropResult: {
        tourPlan: TourPlan;
      } | null = monitor.getDropResult();
      if (item && dropResult) {
        let tempVolume = 0;
        let tempWeight = 0;
        let flatList =
          dropResult.tourPlan?.tourEntryList?.map(tourEntry => tourEntry.relTourPackages).flat() ||
          [];
        flatList?.forEach(relTour => {
          if (relTour.packagesType === "UP") {
            tempVolume += relTour.packages.volume;
            tempWeight += relTour.packages.weight;
          }
        });
        if (
          dropResult.tourPlan.vehicleEntry.volume >= tempVolume + item.package.volume &&
          dropResult.tourPlan.vehicleEntry.weightCapacity >= tempWeight + item.package.weight
        ) {
          setAddressData({
            param: {
              date: format(date, "yyyy-MM-dd"),
              targetAddress: item.package.targetAddress,
              packages: item.package,
              vehicle: dropResult.tourPlan.vehicleEntry,
              tourOrder: 1,
            },
          });
        } else {
          setWarningData({
            param: {
              date: format(date, "yyyy-MM-dd"),
              targetAddress: item.package.targetAddress,
              packages: item.package,
              vehicle: dropResult.tourPlan.vehicleEntry,
              tourOrder: 1,
            },
          });
        }
      }
    },
    canDrag: () => dragEnabled,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.2 : 1,
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      ref={element => {
        dragEnabled && drag(element);
        preview(element);
      }}
    >
      <Box
        key={pack.id}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        minHeight={40}
        borderBottom="1px solid rgba(224, 224, 224, 1)"
        style={{ backgroundColor: palette.lightGray }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            opacity,
          }}
        >
          {dragEnabled ? (
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 12,
              }}
            >
              <DragIndicatorIcon color="disabled" />
            </Typography>
          ) : enableMultiSelect ? (
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 12,
              }}
            >
              {!selectedPackageList?.find(selected => selected.id === pack?.id) ? (
                <IconButton onClick={() => addPackage?.(pack)}>
                  <CheckBoxOutlineBlankOutlined color="primary" />
                </IconButton>
              ) : (
                <IconButton onClick={() => removePackage?.(pack)}>
                  <CheckBoxOutlined color="primary" />
                </IconButton>
              )}
            </Typography>
          ) : (
            <Typography
              style={{
                marginRight: 36,
              }}
            />
          )}
          {columns.packageName && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack.name}
              </Typography>
            </Box>
          )}
          {columns.trackingNumber && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack.trackingNumber}
              </Typography>
            </Box>
          )}
          {columns.cdunumber && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack?.cdunumber}
              </Typography>
            </Box>
          )}
          {columns.orderShopNumber && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack?.shipment?.orderShopNumber}
              </Typography>
            </Box>
          )}
          {columns.orderExternalNumber && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack?.shipment?.orderExternalNumber}
              </Typography>
            </Box>
          )}
          {columns.volume && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {t("common:number", { num: round(pack.volume, 2) })} m³
              </Typography>
            </Box>
          )}
          {columns.weight && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {t("common:number", { num: round(pack.weight, 2) })} kg
              </Typography>
            </Box>
          )}
          {columns.status && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {t(`package.status.${pack?.actualStatus?.status}`)}
              </Typography>
            </Box>
          )}
          {columns.storageCode && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack?.actualStatus?.relPackagesStatusStorages?.map((value, index, array) => {
                  return `${value.storage?.storageCode}${array.length !== index + 1 ? ", " : ""}`;
                })}
              </Typography>
            </Box>
          )}
          {columns.companyName && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack.shop?.company?.name}
              </Typography>
            </Box>
          )}
          {columns.customerName && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack.customerName}
              </Typography>
            </Box>
          )}
          {columns.customerEmail && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack?.customerEmail}
              </Typography>
            </Box>
          )}
          {columns.customerPhone && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack?.customerPhone}
              </Typography>
            </Box>
          )}
          {columns.customerComment && (
            <Box width="100%" padding={0.5}>
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {pack?.customerComment}
              </Typography>
            </Box>
          )}
          {columns.sourceAddress && (
            <Tooltip
              title={
                <>
                  <Typography>{displayAddress(pack.sourceAddress)}</Typography>
                </>
              }
            >
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                padding={0.5}
                justifyContent="space-between"
              >
                <Typography
                  style={{
                    fontSize: 14,
                    wordBreak: "break-word",
                    color: haveDates ? "black" : "#A8A8A8",
                  }}
                >
                  {`${pack.sourceAddress?.zipCode} ${pack.sourceAddress?.city}`}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {columns.currentAddress && (
            <Tooltip title={displayAddress(pack.currentAddress)}>
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                padding={0.5}
                justifyContent="space-between"
              >
                <Typography
                  style={{
                    fontSize: 14,
                    wordBreak: "break-word",
                    color: haveDates ? "black" : "#A8A8A8",
                  }}
                >
                  {`${pack.currentAddress?.zipCode} ${pack.currentAddress?.city}`}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {columns.targetAddress && (
            <Tooltip
              title={
                <>
                  <Typography>{displayAddress(pack.targetAddress)}</Typography>
                </>
              }
            >
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                padding={0.5}
                justifyContent="space-between"
              >
                <Typography
                  style={{
                    fontSize: 14,
                    wordBreak: "break-word",
                    color: haveDates ? "black" : "#A8A8A8",
                  }}
                >
                  {`${pack.targetAddress?.zipCode} ${pack.targetAddress?.city}`}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {columns.targetFromDate && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              padding={0.5}
              justifyContent="space-between"
            >
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {!!pack?.targetDateTo
                  ? format(new Date(pack.targetDateTo), "yyyy.MM.dd. HH:mm")
                  : "-"}
              </Typography>
            </Box>
          )}
          {columns.targetToDate && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              padding={0.5}
              justifyContent="space-between"
            >
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {!!pack?.targetDateTo
                  ? format(new Date(pack.targetDateTo), "yyyy.MM.dd. HH:mm")
                  : "-"}
              </Typography>
            </Box>
          )}
          {columns.sourceFromDate && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              padding={0.5}
              justifyContent="space-between"
            >
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {!!pack?.targetDateTo
                  ? format(new Date(pack.targetDateTo), "yyyy.MM.dd. HH:mm")
                  : "-"}
              </Typography>
            </Box>
          )}
          {columns.sourceToDate && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              padding={0.5}
              justifyContent="space-between"
            >
              <Typography
                style={{
                  fontSize: 14,
                  wordBreak: "break-word",
                  color: haveDates ? "black" : "#A8A8A8",
                }}
              >
                {!!pack?.targetDateTo
                  ? format(new Date(pack.targetDateTo), "yyyy.MM.dd. HH:mm")
                  : "-"}
              </Typography>
            </Box>
          )}
          {columns.icons && (
            <Box
              display="flex"
              flexWrap="wrap"
              width="100%"
              padding={0.5}
              justifyContent="space-between"
            >
              {pack?.isAskLoader ? (
                <Tooltip title={t("package.properties.isAskLoader").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faPeopleCarry}
                      style={{ color: palette.main, margin: 4 }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
              {pack?.isFragile ? (
                <Tooltip title={t("package.properties.isFragile").toString()}>
                  <BrokenImage style={{ color: palette.main, margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
              {pack?.isStackable ? (
                <Tooltip title={t("package.properties.isStackable").toString()}>
                  <Height style={{ color: palette.main, margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
              {pack?.isHazardous ? (
                <Tooltip title={t("package.properties.isHazardous").toString()}>
                  <Warning style={{ color: "orange", margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
              {pack?.isCashOnDelivery ? (
                <Tooltip title={t("package.properties.isCashOnDelivery").toString()}>
                  <img src={CODIcon} alt="" style={{ width: 24, height: 24, margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
              {pack?.isExpressDelivery ? (
                <Tooltip title={t("package.properties.isExpressDelivery").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon icon={faShippingFast} style={{ color: palette.main }} />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
              {pack?.isDeliveryToWarehouse ? (
                <Tooltip title={t("package.properties.isDeliveryToWarehouse").toString()}>
                  <div>
                    <HomeWorkRoundedIcon />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
            </Box>
          )}
          <Box display="flex" padding={0.5} width={"50%"}>
            <Tooltip title={t("common:button.details").toString()}>
              <IconButton onClick={() => history.push(`/package/${pack.id}`)}>
                <DescriptionOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default PackageListItem;
