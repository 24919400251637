import {
  BrokenImage,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  DescriptionOutlined,
  Edit,
  EditLocation,
  Error,
  Height,
  LowPriority,
  Warning,
  WarningOutlined,
} from "@material-ui/icons";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import ModifyCoordinates from "components/ModifyCoordinates";
import usePermissions from "shared/hooks/usePermissions";
import displayAddress from "shared/util/displayAddress";
import CODIcon from "./money.png";

import {
  faBoxesPacking,
  faEdit,
  faPeopleCarry,
  faPersonShelter,
  faRecycle,
  faReply,
  faRotate,
  faRotateLeft,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageLoading from "components/PageLoading";
import { Link } from "components/router";
import { RETURN_GOODS_TYPES } from "config/constants";
import queryClient from "config/query";
import { palette } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { timeWindowCheck } from "shared/network/package.api";
import { Package } from "shared/types";
import { getReturnGoodsBaseIcon } from "views/package/components/PackageReturnGoodsIcon";
import { ColumnType } from "./PackageList";

type Props = {
  pack?: Package;
  columns: ColumnType;
  setSelected: (selected: Package | null) => void;
  setSelectedStorageCode: (selected: Package | null) => void;
  enableMultiSelect: boolean;
  selectedPackageList?: Package[];
  addPackage?: (pack: Package) => void;
  removePackage?: (pack: Package) => void;
};
const useStyles = makeStyles(
  () => ({
    iconData: {
      display: "flex",
      alignItems: "center",
      gridGap: 8,
      fontSize: 16,
      marginLeft: 10,
    },
  }),
  { name: "PackageDetails" },
);

const PackageRow = ({
  pack,
  setSelected,
  setSelectedStorageCode,
  columns,
  enableMultiSelect,
  selectedPackageList,
  addPackage,
  removePackage,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { isAdmin, checkPermissions } = usePermissions();
  const [open, setOpen] = useState<"current" | "target" | "source" | boolean>(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  async function submitSeen(packageId?: number) {
    setLoading(true);
    try {
      if (packageId) {
        await timeWindowCheck(packageId);
      }
      setOpenConfirm(false);
      queryClient.invalidateQueries("packages");
      queryClient.invalidateQueries("packageQuery");
    } catch {
      enqueueSnackbar(t("seen.error"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <>
      <PageLoading open={loading} />
      <Box
        display="flex"
        alignItems="center"
        minHeight={40}
        borderBottom="1px solid rgba(224, 224, 224, 1)"
      >
        {enableMultiSelect && !!pack ? (
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 12,
            }}
          >
            {!selectedPackageList?.find(entry => entry.id === pack?.id) ? (
              <IconButton
                onClick={() => {
                  addPackage?.(pack);
                }}
              >
                <CheckBoxOutlineBlankOutlined color="primary" />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  removePackage?.(pack);
                }}
              >
                <CheckBoxOutlined color="primary" />
              </IconButton>
            )}
          </Typography>
        ) : (
          <Typography
            style={{
              marginRight: 36,
            }}
          />
        )}
        {columns.packageName && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>{pack?.name}</Typography>
          </Box>
        )}
        {columns.trackingNumber && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>{pack?.trackingNumber}</Typography>
          </Box>
        )}
        {columns.plannedToTour && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>
              {pack?.plannedToVehicle
                ? `${!!pack?.plannedDate && format(new Date(pack.plannedDate), "yyyy. MM. dd.")} (${
                    pack?.plannedToVehicle
                  })`
                : "-"}
            </Typography>
          </Box>
        )}
        {columns.cdunumber && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>{pack?.cdunumber}</Typography>
          </Box>
        )}
        {columns.orderShopNumber && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>{pack?.shipment?.orderShopNumber}</Typography>
          </Box>
        )}
        {columns.orderExternalNumber && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>{pack?.shipment?.orderExternalNumber}</Typography>
          </Box>
        )}
        {columns.volume && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography style={{ textAlign: "right" }}>
              {t("common:number", { num: pack?.volume }) + " m³"}
            </Typography>
          </Box>
        )}
        {columns.weight && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>{t("common:number", { num: pack?.weight }) + " kg"}</Typography>
          </Box>
        )}
        {columns.status && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            minWidth="250px"
            padding={0.5}
          >
            <Typography>{t(`package.status.${pack?.actualStatus?.status}`)}</Typography>
          </Box>
        )}
        {columns.storageCode && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            minWidth="250px"
            padding={0.5}
          >
            <Typography>
              {pack?.actualStatus?.relPackagesStatusStorages?.map((value, index, array) => {
                return `${value.storage?.storageCode}${array.length !== index + 1 ? ", " : ""}`;
              })}
            </Typography>
          </Box>
        )}
        {columns.companyName && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            minWidth="250px"
            padding={0.5}
          >
            <Typography>{pack?.shop?.company?.name}</Typography>
          </Box>
        )}
        {columns.customerName && (
          <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
            <Typography>{pack?.customerName}</Typography>
          </Box>
        )}
        {columns.customerEmail && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            minWidth="250px"
            padding={0.5}
          >
            <Typography>{pack?.customerEmail}</Typography>
          </Box>
        )}
        {columns.customerPhone && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            minWidth="250px"
            padding={0.5}
          >
            <Typography>{pack?.customerPhone}</Typography>
          </Box>
        )}
        {columns.customerComment && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            minWidth="250px"
            padding={0.5}
          >
            <Typography>{pack?.customerComment}</Typography>
          </Box>
        )}
        {!isAdmin && columns.sourceAddress && (
          <Tooltip title={<Typography>{displayAddress(pack?.sourceAddress)}</Typography>}>
            <Box width="100%" minWidth="250px" padding={0.5}>
              <Typography>
                {`${pack?.sourceAddress?.zipCode} ${pack?.sourceAddress?.city}`}
              </Typography>
            </Box>
          </Tooltip>
        )}
        {isAdmin && columns.sourceAddress && (
          <Tooltip title={<Typography>{displayAddress(pack?.sourceAddress)}</Typography>}>
            <Box width="100%" minWidth="250px" padding={0.5}>
              <Box display="flex" alignItems="center">
                <Tooltip title={t("map.coordinatesModify").toString()}>
                  <IconButton size="small" onClick={() => setOpen("source")}>
                    <EditLocation />
                  </IconButton>
                </Tooltip>
                <Typography>
                  {`${pack?.sourceAddress?.zipCode} ${pack?.sourceAddress?.city}`}
                </Typography>
              </Box>
              {pack && open === "source" && (
                <ModifyCoordinates address={pack.sourceAddress} open={open} setOpen={setOpen} />
              )}
            </Box>
          </Tooltip>
        )}
        {!isAdmin && columns.currentAddress && (
          <Tooltip title={<Typography>{displayAddress(pack?.currentAddress)}</Typography>}>
            <Box width="100%" minWidth="250px" padding={0.5}>
              <Typography>
                {`${pack?.currentAddress?.zipCode} ${pack?.currentAddress?.city}`}
              </Typography>
            </Box>
          </Tooltip>
        )}
        {isAdmin && columns.currentAddress && (
          <Tooltip title={displayAddress(pack?.currentAddress)}>
            <Box width="100%" minWidth="250px" padding={0.5}>
              <Box display="flex" alignItems="center">
                <Tooltip title={t("map.coordinatesModify").toString()}>
                  <IconButton size="small" onClick={() => setOpen("current")}>
                    <EditLocation />
                  </IconButton>
                </Tooltip>
                <Typography>
                  {`${pack?.currentAddress?.zipCode} ${pack?.currentAddress?.city}`}
                </Typography>
              </Box>
              {pack && open === "current" && (
                <ModifyCoordinates address={pack.currentAddress} open={open} setOpen={setOpen} />
              )}
            </Box>
          </Tooltip>
        )}
        {!isAdmin && columns.targetAddress && (
          <Tooltip title={<Typography>{displayAddress(pack?.targetAddress)}</Typography>}>
            <Box width="100%" minWidth="250px" padding={0.5}>
              <Typography>
                {`${pack?.targetAddress?.zipCode} ${pack?.targetAddress?.city}`}
              </Typography>
            </Box>
          </Tooltip>
        )}
        {isAdmin && columns.targetAddress && (
          <Tooltip title={<Typography>{displayAddress(pack?.targetAddress)}</Typography>}>
            <Box width="100%" minWidth="250px" padding={0.5}>
              <Box display="flex" alignItems="center">
                <Tooltip title={t("map.coordinatesModify").toString()}>
                  <IconButton size="small" onClick={() => setOpen("target")}>
                    <EditLocation />
                  </IconButton>
                </Tooltip>
                <Typography>
                  {`${pack?.targetAddress?.zipCode} ${pack?.targetAddress?.city}`}
                </Typography>
              </Box>
              {pack && open === "target" && (
                <ModifyCoordinates address={pack.targetAddress} open={open} setOpen={setOpen} />
              )}
            </Box>
          </Tooltip>
        )}
        {columns.targetFromDate && (
          <Box width="100%" minWidth="250px" padding={0.5}>
            <Typography>
              {!!pack?.targetDateFrom
                ? format(new Date(pack.targetDateFrom), "yyyy.MM.dd. HH:mm")
                : "-"}
            </Typography>
          </Box>
        )}
        {columns.targetToDate && (
          <Box width="100%" minWidth="250px" padding={0.5}>
            <Typography>
              {!!pack?.targetDateTo
                ? format(new Date(pack.targetDateTo), "yyyy.MM.dd. HH:mm")
                : "-"}
            </Typography>
          </Box>
        )}
        {columns.sourceFromDate && (
          <Box width="100%" minWidth="250px" padding={0.5}>
            <Typography>
              {!!pack?.sourceDateFrom
                ? format(new Date(pack.sourceDateFrom), "yyyy.MM.dd. HH:mm")
                : "-"}
            </Typography>
          </Box>
        )}
        {columns.sourceToDate && (
          <Box width="100%" minWidth="250px" padding={0.5}>
            <Typography>
              {!!pack?.sourceDateTo
                ? format(new Date(pack.sourceDateTo), "yyyy.MM.dd. HH:mm")
                : "-"}
            </Typography>
          </Box>
        )}
        {columns.icons && (
          <Box
            display="flex"
            alignContent="flex-start"
            flexDirection="column"
            width="100%"
            minWidth="500px"
            padding={0.5}
            maxHeight="100px"
          >
            <Box display="flex" alignItems="center" width="100%" minWidth="150px">
              {pack?.isPUP ? (
                <Tooltip title={t("package.properties.isPUP").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faBoxesPacking}
                      style={{ color: palette.main, margin: 4 }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isAskLoader ? (
                <Tooltip title={t("package.properties.isAskLoader").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faPeopleCarry}
                      style={{ color: palette.main, margin: 4 }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isFragile ? (
                <Tooltip title={t("package.properties.isFragile").toString()}>
                  <BrokenImage style={{ color: palette.main, margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isStackable ? (
                <Tooltip title={t("package.properties.isStackable").toString()}>
                  <Height style={{ color: palette.main, margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isHazardous ? (
                <Tooltip title={t("package.properties.isHazardous").toString()}>
                  <Warning style={{ color: "orange", margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isCashOnDelivery ? (
                <Tooltip title={t("package.properties.isCashOnDelivery").toString()}>
                  <div className={classes.iconData}>
                    <img src={CODIcon} alt="" style={{ width: 24, height: 24, margin: 4 }} />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 9 }} />
              )}
              {pack?.isExpressDelivery ? (
                <Tooltip title={t("package.properties.isExpressDelivery").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faShippingFast}
                      style={{
                        color: palette.main,
                        margin: 4,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isDeliveryToWarehouse ? (
                <Tooltip title={t("package.properties.isDeliveryToWarehouse").toString()}>
                  <div className={classes.iconData}>
                    <HomeWorkRoundedIcon />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.markedToReturn ? (
                <Tooltip title={t("package.properties.markedToReturn").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faReply}
                      style={{
                        color: palette.main,
                        margin: 4,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {/* --- */}
              {pack?.isPackageChange ? (
                <Tooltip title={t("package.properties.isPackageChange").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon icon={faRotate} style={{ color: palette.main, margin: 4 }} />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isReturnPackage1 ? (
                <Tooltip title={t("package.properties.isReturnPackage1").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faRotateLeft}
                      style={{ color: palette.main, margin: 4 }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isReturnPackage2 ? (
                <Tooltip title={t("package.properties.isReturnPackage2").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon icon={faRecycle} style={{ color: palette.main, margin: 4 }} />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
              {pack?.isOneLoader ? (
                <Tooltip title={t("package.properties.isOneLoader").toString()}>
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faPersonShelter}
                      style={{ color: palette.main, margin: 4 }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ minWidth: 28, margin: 4 }} />
              )}
            </Box>
            <Box display="flex" alignItems="center" width="100%" minWidth="150px">
              {RETURN_GOODS_TYPES.map((type, index) => {
                return !!pack?.shipment?.returnGoods?.find(entry => entry.type === type) ? (
                  <Tooltip key={index} title={t(`package.returnGoods.${type}`).toString()}>
                    <div className={classes.iconData}>
                      <FontAwesomeIcon
                        icon={getReturnGoodsBaseIcon(type)}
                        style={{ color: palette.main }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div key={index} style={{ minWidth: 28 }} />
                );
              })}
            </Box>
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" width="100%" minWidth="250px" padding={0.5}>
          {(isAdmin ||
            checkPermissions({
              requestedPermissions: ["WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"],
            })) &&
            !!pack?.actualStatus?.relPackagesStatusStorages?.[0]?.storage?.storageCode && (
              <Tooltip title={t("package.storageCodeTitle").toString()}>
                <IconButton onClick={() => setSelectedStorageCode(pack || null)}>
                  <LowPriority />
                </IconButton>
              </Tooltip>
            )}
          {pack?.targetDateModifiedStatus === "PENDING" && (
            <Tooltip title={t("targetDateModifiedStatus.pending").toString()}>
              <IconButton onClick={() => setOpenConfirm(true)}>
                <Error style={{ color: "gold" }} />
              </IconButton>
            </Tooltip>
          )}
          {pack?.targetDateModifiedStatus === "MODIFIED" && (
            <Tooltip title={t("targetDateModifiedStatus.modified").toString()}>
              <IconButton onClick={() => setOpenConfirm(true)}>
                <WarningOutlined style={{ color: "crimson" }} />
              </IconButton>
            </Tooltip>
          )}
          <Dialog open={openConfirm}>
            <DialogTitle>{t("seen.title")}</DialogTitle>
            <DialogContent>{t("seen.body")}</DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirm(false)}>Mégse</Button>
              <Button variant="contained" color="primary" onClick={() => submitSeen(pack?.id)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Tooltip title={t("package.status.modify").toString()}>
            <IconButton onClick={() => setSelected(pack || null)}>
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("common:button.modify").toString()}>
            <Link to={`/package/${pack?.id}/modify`}>
              <IconButton>
                <Edit />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title={t("common:button.details").toString()}>
            <Link to={`/package/${pack?.id}`}>
              <IconButton>
                <DescriptionOutlined />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default PackageRow;
