import { faPeopleCarry, faShippingFast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { BrokenImage, Edit, GetApp, Height, Visibility, Warning } from "@material-ui/icons";
import Layout from "components/layout/Layout";
import Loading from "components/Loading";
import PackageStatusModal from "components/package/PackageStatusModal";
import { Link } from "components/router";
import ShippingPriceList from "components/shippingPrices/ShippingPriceList";
import { palette } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import usePermissions from "shared/hooks/usePermissions";
import { getNotes } from "shared/network/notes.api";
import { getPackageById, getPackageLabelPdf, getPackageNotePdf } from "shared/network/package.api";
import { Package } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import fileDownload from "shared/util/fileDownload";
import fileOpen from "shared/util/fileOpen";
import PackageNote from "views/package/components/PackageNote";
import PackageNoteCreate from "views/package/components/PackageNoteCreate";
import PackageStatusHistory from "views/package/components/PackageStatusHistory";
import getReturnGoodsIcon from "./components/PackageReturnGoodsIcon";

const useStyles = makeStyles(
  () => ({
    header: {
      color: palette.main,
      fontSize: 16,
      borderBottom: "1px solid darkgray",
      marginBottom: 16,
    },
    title: {
      color: "grey",
    },
    data: {
      fontSize: 16,
      marginBottom: 8,
    },
    listData: {
      fontSize: 16,
      marginBottom: 12,
    },
    iconData: {
      display: "flex",
      alignItems: "center",
      gridGap: 8,
      fontSize: 16,
      marginBottom: 8,
    },
    listIconData: {
      display: "flex",
      alignItems: "center",
      gridGap: 8,
      fontSize: 16,
      marginBottom: 12,
    },
  }),
  { name: "PackageDetails" },
);

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const PackageDetails = ({ match }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const { isCustomerService, isAdmin, user, companyPermissions } = usePermissions();
  const isShipperOrWarehouse =
    companyPermissions?.includes("SHIPPER") ||
    companyPermissions?.includes("WAREHOUSEKEEPER") ||
    companyPermissions?.includes("WAREHOUSEKEEPER_ADMIN");
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

  const { data: pack, refetch, isFetching } = useQuery("package", async () => {
    const { data } = await getPackageById(Number.parseInt(id));
    return data.item;
  });

  const packagesNotesQuery = useQuery(
    ["packagesNotesQuery", pack?.id],
    async () => {
      if (pack?.id) {
        const { data } = await getNotes({
          subjectType: "PACKAGES",
          subjectId: pack.id,
        });
        return data.items;
      }
      return Promise.reject();
    },
    { enabled: !!pack?.id },
  );
  const shipmentNotesQuery = useQuery(
    ["shipmentNotesQuery", pack?.shipment?.id],
    async () => {
      if (pack?.shipment?.id) {
        const { data } = await getNotes({
          subjectType: "SHIPMENT",
          subjectId: pack.shipment.id,
        });
        return data.items;
      }
      return Promise.reject();
    },
    { enabled: !!pack?.shipment?.id },
  );

  const [loading, setLoading] = useState<"LABEL" | "DOWNLOAD_NOTE" | "OPEN_NOTE" | null>(null);

  const downloadLabelPdf = async () => {
    setLoading("LABEL");
    try {
      const { data } = await getPackageLabelPdf(Number(pack?.shipment?.id));
      if (pack) {
        fileDownload(data, `${pack.trackingNumber}_cimke.pdf`);
      }
    } catch {
      enqueueSnackbar(t("downloadError"), { variant: "error" });
    }
    setLoading(null);
  };

  const downloadNotePdf = async () => {
    setLoading("DOWNLOAD_NOTE");
    try {
      const { data } = await getPackageNotePdf(Number(id));
      if (pack) {
        fileDownload(data, `${pack.trackingNumber}_szallitolevel.pdf`);
      }
    } catch {
      enqueueSnackbar(t("downloadError"), { variant: "error" });
    }
    setLoading(null);
  };

  const openNotePdf = async () => {
    setLoading("OPEN_NOTE");
    try {
      const { data } = await getPackageNotePdf(Number(id));
      fileOpen(data);
    } catch {
      enqueueSnackbar(t("downloadError"), { variant: "error" });
    }
    setLoading(null);
  };

  return (
    <>
      <Layout
        title={t("package.details")}
        actionButton={
          <>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 8 }}
              onClick={() => {
                if (pack) {
                  setSelectedPackage(pack);
                }
              }}
              startIcon={<Edit />}
            >
              {t("package.status.modify")}
            </Button>
            <PackageStatusModal
              pack={selectedPackage}
              setSelected={setSelectedPackage}
              refetch={refetch}
            />

            {pack?.labelPdfPath && (
              <Button
                onClick={async () => downloadLabelPdf()}
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                startIcon={loading === "LABEL" ? <CircularProgress size={20} /> : <GetApp />}
              >
                {t("package.LABEL")}
              </Button>
            )}
            {(pack?.deliveryNote || pack?.deliveryNoteSigned) && (
              <>
                <Button
                  onClick={async () => downloadNotePdf()}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 8 }}
                  startIcon={
                    loading === "DOWNLOAD_NOTE" ? <CircularProgress size={20} /> : <GetApp />
                  }
                >
                  {t("package.DELIVERY_NOTE")}
                </Button>
                <Button
                  onClick={async () => openNotePdf()}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 8 }}
                  startIcon={
                    loading === "OPEN_NOTE" ? <CircularProgress size={20} /> : <Visibility />
                  }
                >
                  {t("package.DELIVERY_NOTE")}
                </Button>
              </>
            )}
            {(isAdmin || !(user?.userType === "NORMAL" && isShipperOrWarehouse)) && (
              <Tooltip title={t("common:button.modify").toString()}>
                <Link to={`/package/${pack?.id}/modify`}>
                  <IconButton>
                    <Edit />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {/* {pack?.labelPdfPath && (
            <Button
              onClick={async () => {
                const { data } = await getFileByPath(pack.labelPdfPath);
                fileDownload(data, `${pack.trackingNumber}_cimke.pdf`);
              }}
              variant="outlined"
              color="primary"
              style={{ marginRight: 8 }}
              startIcon={<GetApp />}
            >
              {t("package.LABEL")}
            </Button>
          )}
          {(pack?.deliveryNote || pack?.deliveryNoteSigned) && (
            <>
              <Button
                onClick={async () => {
                  if (pack?.deliveryNoteSigned) {
                    const { data } = await getFileByPath(pack.deliveryNoteSigned);
                    fileDownload(data, `${pack.trackingNumber}_szallitolevel.pdf`);
                  } else {
                    const { data } = await getFileByPath(pack.deliveryNote);
                    fileDownload(data, `${pack.trackingNumber}_szallitolevel.pdf`);
                  }
                }}
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                startIcon={<GetApp />}
              >
                {t("package.DELIVERY_NOTE")}
              </Button>
              <Button
                onClick={async () => {
                  if (pack?.deliveryNoteSigned) {
                    const { data } = await getFileByPath(pack.deliveryNoteSigned);
                    fileOpen(data);
                  } else {
                    const { data } = await getFileByPath(pack.deliveryNote);
                    fileOpen(data);
                  }
                }}
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                startIcon={<Visibility />}
              >
                {t("package.DELIVERY_NOTE")}
              </Button>
            </>
          )} */}
          </>
        }
      >
        {isFetching ? (
          <Loading />
        ) : (
          <>
            <Typography className={classes.header}>Szállítás</Typography>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>{t("package.properties.trackingNumber")}</div>
                <div className={classes.data}>{pack?.trackingNumber}</div>
                <div className={classes.title}>{t("package.properties.shopPackagesNumber")}</div>
                <div className={classes.data}>{pack?.shopPackagesNumber || "-"}</div>
                {pack?.shipment?.orderShopNumber && (
                  <>
                    <div className={classes.title}>{t("package.properties.orderShopNumber")}</div>
                    <div className={classes.data}>{pack?.shipment?.orderShopNumber}</div>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>{t("package.actualStatus")}</div>
                <div className={classes.data}>
                  {t(`package.status.${pack?.actualStatus.status}`)}
                </div>
                {pack && (
                  <div
                    style={{
                      width: "max-content",
                    }}
                  >
                    <PackageStatusHistory pack={pack} />
                  </div>
                )}
              </Grid>
            </Grid>
            <Typography className={classes.header}>Cím</Typography>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>{t("package.properties.customerName")}</div>
                <div className={classes.data}>{pack?.customerName}</div>
                <div className={classes.title}>{t("package.properties.customerPhone")}</div>
                <div className={classes.data}>{pack?.customerPhone}</div>
                <div className={classes.title}>{t("package.properties.customerEmail")}</div>
                <div className={classes.data}>{pack?.customerEmail}</div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>{t("package.targetAddress")}</div>
                <div className={classes.data}>{displayAddress(pack?.targetAddress)}</div>
                <div className={classes.title}>{t("package.currentAddress")}</div>
                <div className={classes.data}>{displayAddress(pack?.currentAddress)}</div>
                <div className={classes.title}>{t("package.sourceAddress")}</div>
                <div className={classes.data}>{displayAddress(pack?.sourceAddress)}</div>
              </Grid>
            </Grid>
            <Typography className={classes.header}>Csomag részletei</Typography>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>
                  {t("package.properties.width")} × {t("package.properties.length")} ×{" "}
                  {t("package.properties.height")}
                </div>
                <div className={classes.data}>
                  {t("common:number", { num: pack?.width })} ×{" "}
                  {t("common:number", { num: pack?.length })} ×{" "}
                  {t("common:number", { num: pack?.height })} (m)
                </div>
                <div className={classes.title}>{t("package.properties.volume")}</div>
                <div className={classes.data}>{t("common:number", { num: pack?.volume })} m³</div>
                <div className={classes.title}>{t("package.properties.weight")}</div>
                <div className={classes.data}>{t("common:number", { num: pack?.weight })} kg</div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                {pack?.customerComment && (
                  <div style={{ marginBottom: 16 }}>
                    <div className={classes.title}>{t("package.properties.customerComment")}</div>
                    <div className={classes.data}>{pack?.customerComment}</div>
                  </div>
                )}
                {pack?.isAskLoader && (
                  <div className={classes.iconData}>
                    <FontAwesomeIcon
                      icon={faPeopleCarry}
                      style={{ color: palette.main, margin: 4 }}
                    />
                    <span>{t("package.properties.isAskLoader")} </span>
                  </div>
                )}
                {pack?.isFragile && (
                  <div className={classes.iconData}>
                    <BrokenImage style={{ color: palette.main }} />
                    <span>{t("package.properties.isFragile")} </span>
                  </div>
                )}
                {pack?.isStackable && (
                  <div className={classes.iconData}>
                    <Height style={{ color: palette.main }} />
                    <span>{t("package.properties.isStackable")} </span>
                  </div>
                )}
                {pack?.isHazardous && (
                  <div className={classes.iconData}>
                    <Warning style={{ color: "orange" }} />
                    <span>{t("package.properties.isHazardous")} </span>
                  </div>
                )}
                {pack?.isExpressDelivery && (
                  <div className={classes.iconData}>
                    <FontAwesomeIcon icon={faShippingFast} style={{ color: palette.main }} />
                    <span>{t("package.properties.isExpressDelivery")} </span>
                  </div>
                )}
                {pack?.isUniqueInsurance && (
                  <div className={classes.iconData}>
                    <span>{t("package.properties.isUniqueInsurance")} </span>
                  </div>
                )}
              </Grid>
            </Grid>
            <Typography className={classes.header}>Időpontok</Typography>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>{t("package.properties.sourceDateFrom")}</div>
                {!!pack?.sourceDateFrom ? (
                  <div className={classes.data}>
                    {format(new Date(pack.sourceDateFrom), "yyyy.MM.dd. HH:mm")}
                  </div>
                ) : (
                  <div className={classes.data}>{"-"}</div>
                )}
                <div className={classes.title}>{t("package.properties.sourceDateTo")}</div>
                {!!pack?.sourceDateTo ? (
                  <div className={classes.data}>
                    {format(new Date(pack.sourceDateTo), "yyyy.MM.dd. HH:mm")}
                  </div>
                ) : (
                  <div className={classes.data}>{"-"}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>{t("package.properties.targetDateFrom")}</div>
                {!!pack?.targetDateFrom ? (
                  <div className={classes.data}>
                    {format(new Date(pack.targetDateFrom), "yyyy.MM.dd. HH:mm")}
                  </div>
                ) : (
                  <div className={classes.data}>{"-"}</div>
                )}
                <div className={classes.title}>{t("package.properties.targetDateTo")}</div>
                {!!pack?.targetDateTo ? (
                  <div className={classes.data}>
                    {format(new Date(pack.targetDateTo), "yyyy.MM.dd. HH:mm")}
                  </div>
                ) : (
                  <div className={classes.data}>{"-"}</div>
                )}
              </Grid>
            </Grid>
            <Typography className={classes.header}>Költségek</Typography>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                {!(
                  (user?.userType === "NORMAL" && companyPermissions?.includes("SHIPPER")) ||
                  companyPermissions?.includes("WAREHOUSEKEEPER") ||
                  companyPermissions?.includes("WAREHOUSEKEEPER_ADMIN")
                ) && (
                  <>
                    <div className={classes.title}>
                      {t("package.properties.deliveryPriceToWebshop")}
                    </div>
                    <div className={classes.data}>
                      {pack?.deliveryPriceToWebshop
                        ? `${t("common:number", {
                            num: pack?.deliveryPriceToWebshop,
                          })} Ft`
                        : "-"}
                    </div>
                  </>
                )}
                <div className={classes.title}>{t("package.properties.vatPercent")}</div>
                <div className={classes.data}>
                  {pack?.vatPercent
                    ? `${t("common:number", {
                        num: pack?.vatPercent,
                      })} %`
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <div className={classes.title}>{t("package.properties.value")}</div>
                <div className={classes.data}>{t("common:number", { num: pack?.value })} Ft</div>
                <div className={classes.data} style={{ fontWeight: "bold" }}>
                  {pack?.isCashOnDelivery && t("package.properties.isCashOnDelivery")}
                  {pack?.isCashOnDelivery &&
                    pack?.actualStatus.status === "SUCCESSFUL_DELIVERY" &&
                    pack?.paymentDeliveryType === "CARD" &&
                    ", " + t("package.CARD")}
                  {pack?.isCashOnDelivery &&
                    pack?.actualStatus.status === "SUCCESSFUL_DELIVERY" &&
                    pack?.paymentDeliveryType === "CASH" &&
                    ", " + t("package.CASH")}
                  {!pack?.isCashOnDelivery && t("package.paid")}
                </div>
              </Grid>
            </Grid>
            {pack?.shippingPrices && isCustomerService && isAdmin && (
              <>
                <Typography className={classes.header}>{t("package.shippingPrice")}</Typography>
                <ShippingPriceList
                  shippingPrices={pack.shippingPrices}
                  modifySubmitSuccess={refetch}
                />
              </>
            )}
            <Grid container>
              <Grid item xs={12} sm={12} className={classes.header} style={{ marginBottom: 16 }}>
                {t("package.returnGoods.title")}
              </Grid>
              {pack?.returnGoods?.map((entry, index) => {
                return (
                  <Grid key={index} item container>
                    <Grid item xs={1}>
                      <div className={classes.listIconData}>
                        {getReturnGoodsIcon(entry.type, { color: palette.main })}
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.listData}>
                        {t(`package.returnGoods.${entry.type}`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.listData}>
                        {t("common:number", { num: entry.quantity }) + " " + t("common:count")}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {pack && (
              <>
                <div
                  className={classes.header}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: 8,
                  }}
                >
                  <Typography>{t("package.note.comment")} </Typography>
                  <PackageNoteCreate pack={pack} onSubmitSuccess={shipmentNotesQuery.refetch} />
                </div>
                {packagesNotesQuery.data?.map(note => (
                  <PackageNote key={note.id} note={note} pack={pack} />
                ))}
                {shipmentNotesQuery.data?.map(note => (
                  <PackageNote key={note.id} note={note} pack={pack} />
                ))}
              </>
            )}
          </>
        )}
      </Layout>
    </>
  );
};

export default PackageDetails;
