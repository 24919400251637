import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Loading from "components/Loading";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { listVehicleInfo, saveVehicleInfo } from "shared/network/vehicle-info.api";
import { Vehicle } from "shared/types";
import VehicleInfoDialogRow from "./VehicleInfoDialogRow";

export type Props = {
  vehicleInfoOpen: boolean;
  setVehicleInfoOpen: Dispatch<SetStateAction<boolean>>;
  date: Date;
  vehicles: Vehicle[];
  refetch: () => void;
};

export type FormValues = {
  rows: {
    id: number;
    vehicleId: number;
    tourLength: number;
    link: string;
  }[];
};

const VehicleInfoDialog = ({
  vehicleInfoOpen,
  setVehicleInfoOpen,
  date,
  vehicles,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const { control, handleSubmit } = form;
  const [removed, setRemoved] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<boolean>(false);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "rows",
    keyName: "key",
  });

  const listVehicleInfoQuery = useQuery(
    "listVehicleInfoQuery",
    async () => {
      const { data } = await listVehicleInfo(format(date, "yyyy-MM-dd"));
      return data?.items;
    },
    {
      onSuccess: data => {
        data.forEach(value => append({ vehicleId: value?.vehicle?.id, ...value }));
      },
    },
  );

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await saveVehicleInfo([
        ...(values?.rows || [])?.map(value => {
          return {
            ...value,
            deliveryDate: format(date, "yyyy-MM-dd"),
            id: value?.id || null,
            vehicle: vehicles?.find(vehicle => vehicle?.id === Number(value?.vehicleId)),
            isDelete: false,
          };
        }),
        ...removed,
      ]);
      setVehicleInfoOpen(false);
      refetch?.();
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={vehicleInfoOpen}
      onClose={() => setVehicleInfoOpen(false)}
    >
      <DialogTitle>{t("vehicleInfo.dialogTitle")}</DialogTitle>
      {listVehicleInfoQuery?.isFetching ? (
        <Loading />
      ) : (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              {fields?.map((field, index) => {
                return (
                  <VehicleInfoDialogRow
                    key={field.key}
                    field={field}
                    index={index}
                    remove={remove}
                    setRemoved={setRemoved}
                    vehicles={vehicles}
                    isChange={isChange}
                    setIsChange={setIsChange}
                  />
                );
              })}
              <Box display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  disabled={fields?.length === vehicles?.length}
                  startIcon={<Add />}
                  onClick={() => append({ vehicleId: "", tourLength: "", link: "" })}
                >
                  {t("common:button.add")}
                </Button>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setVehicleInfoOpen(false)}>{t("common:button.cancel")}</Button>
              <Button
                disabled={loading || (!removed?.length && fields?.length === 0)}
                type="submit"
                variant="contained"
                color="primary"
              >
                {loading ? <CircularProgress size={24} /> : t("common:button.save")}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      )}
    </Dialog>
  );
};

export default VehicleInfoDialog;
