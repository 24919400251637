import axios from "config/axios";
import {
  Address,
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
  Package,
  PackageModifyResponse,
  PackageResponse,
} from "shared/types";

const ENDPOINT = "/packages";

export type FilterSubmitValues = {
  search?: string;
  isDesc?: boolean;
  sourceDateFrom?: string;
  sourceDateTo?: string;
  statuses?: string[];
  targetDateFrom?: string;
  targetDateTo?: string;
  vehicleId?: number;
  warehouseId?: number;
  shopId?: number;
  trackingNumber?: string;
  sort?: string;
  sourceAddressZipCode?: string;
  sourceAddressCity?: string;
  sourceAddressAreaName?: string;
  sourceAddressAreaType?: string;
  sourceAddressHouseNumber?: string;
  currentAddressZipCode?: string;
  currentAddressCity?: string;
  currentAddressAreaName?: string;
  currentAddressAreaType?: string;
  currentAddressHouseNumber?: string;
  targetAddressZipCode?: string;
  targetAddressCity?: string;
  targetAddressAreaName?: string;
  targetAddressAreaType?: string;
  targetAddressHouseNumber?: string;
  shopPackagesNumber?: string;
  customerName?: string;
  companyId?: number;
  isAskLoader?: boolean;
  isPUP?: boolean;
  isHazardous?: boolean;
  targetDateNull?: boolean;
  isFragile?: boolean;
  isStackable?: boolean;
  isCashOnDelivery?: boolean;
  isDeliveryToWarehouse?: boolean;
  failedDelivery?: boolean;
  isExpressDelivery?: boolean;
  plannedToTour?: boolean;
  markedToReturn?: boolean;
  ignoreStatuses?: boolean;
  sameTargetAddressWithBilling?: boolean;
  name?: string;
  date?: string;
};

export const savePackages = (data: PackageResponse) =>
  axios.post<GenericResponse<Package>>(ENDPOINT, data, {
    timeout: 1800000,
  });

export const createMorePackages = (data: PackageResponse) =>
  axios.post<GenericResponse<Package>>(`${ENDPOINT}/create-more`, data, {
    timeout: 1800000,
  });

export const modifyPackages = (data: PackageModifyResponse) => axios.put(ENDPOINT, data);

export const modifyPackagesList = (data: {
  packagesList: any[];
  companyId: number;
  sourceAddress?: Address;
  adminShippingPrice: boolean;
}) => axios.post(`${ENDPOINT}/modify-more`, data);

export const listNotDeliveredPackages = () =>
  axios.get<GenericListResponse<Package>>(`${ENDPOINT}/list-not-on-tour`);

export const listNotOnTour = () =>
  axios.get<GenericListResponse<Package>>(`${ENDPOINT}/list-on-tour`);

export const getAllPackagesOnStorageByWarehouseId = (warehouseId: number) =>
  axios.get<GenericListResponse<Package>>(`${ENDPOINT}/all-on-storage?warehouseId=${warehouseId}`);

export const getPackageById = (id: number) =>
  axios.get<GenericResponse<Package>>(`${ENDPOINT}?id=${id}`);

export const getPackageListById = (id: number) =>
  axios.get<GenericListResponse<Package>>(`${ENDPOINT}/list-by-id?id=${id}`);

export const searchPackages = ({
  pageNumber,
  pageSize = 10,
  data = {},
}: {
  pageNumber: number;
  pageSize?: number | null;
  data?: FilterSubmitValues;
}) =>
  axios.post<GenericPageResponse<Package>>(
    data?.search
      ? `${ENDPOINT}/search?page=${pageNumber}&size=${pageSize}&search=${data?.search}`
      : `${ENDPOINT}/search?page=${pageNumber}&size=${pageSize}`,
    { ...data, search: undefined },
  );

export const removePackageFromTour = (data: {
  tourId1: number;
  tourId2: number;
  packagesId: number;
}) =>
  axios.put(
    `${ENDPOINT}/remove-from-tour?tourId1=${data.tourId1}&tourId2=${data.tourId2}&packagesId=${data.packagesId}`,
  );

export const modifyPackageStatus = (data: {
  companyId: number;
  type: string;
  shipmentId?: number;
  packageIds?: number[];
  status: string;
  storageCode?: string;
  paymentType?: string;
  comment?: string | null;
  refuseType?: string | null;
}) => axios.put<{ packageSize: number | null }>(`/packagesstatus/update/new`, data);

export const transferPackage = (data: any) => axios.put(`/packagesstatus/transfer`, data);

export const packageFileUpload = (data: FormData) =>
  axios.post(`${ENDPOINT}/from-file`, data, {
    timeout: 1800000,
    headers: { "Content-Type": "multipart/form-data" },
  });

export const getPackageByTrackingNumber = (trackingNumber: string) =>
  axios.get<GenericListResponse<Package>>(`${ENDPOINT}/tracking?trackingNumber=${trackingNumber}`);

export const exportPackages = (data: FilterSubmitValues) =>
  axios.post(`${ENDPOINT}/export`, data, {
    responseType: "blob",
    timeout: 1800000,
  });

export const exportWarehousePackages = (data: FilterSubmitValues) =>
  axios.post(`${ENDPOINT}/list-by-warehouse-and-date`, data, {
    responseType: "blob",
  });

export const createSignature = ({
  file,
  companyId,
  packageId,
}: {
  file: File;
  companyId: number;
  packageId: number;
}) => {
  const formData = new FormData();
  formData.set("file", file);
  formData.set("companyId", companyId.toString());
  formData.set("packageId", packageId.toString());

  return axios.post(`${ENDPOINT}/signature`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getPackageSampleCSV = () => axios.get(`${ENDPOINT}/sample-csv`);

export const packageTracking = (trackingNumber: string) =>
  axios.get<GenericListResponse<Package>>(`${ENDPOINT}/tracking?trackingNumber=${trackingNumber}`);

export const getShipmentPackages = (shippingId: number) =>
  axios.get<GenericListResponse<Package>>(`${ENDPOINT}/tracking-new?shipingId=${shippingId}`);

export const modifyShipmentWindow = (
  packages: Package[],
  targetDateFrom: string,
  targetDateTo: string,
) =>
  axios.post<GenericListResponse<Package>>(`${ENDPOINT}/modify-dates`, {
    packages,
    targetDateFrom,
    targetDateTo,
  });

export const timeWindowCheck = (param: number) =>
  axios.post(`${ENDPOINT}/time-window-check`, { param });

export const getPackageLabelPdf = (shipmentId: number) =>
  axios.post(`${ENDPOINT}/pdf-by-id`, { param: shipmentId }, { responseType: "blob" });

export const getPackageNotePdf = (packagesId: number, getQr = false) =>
  axios.post(`${ENDPOINT}/pdf`, { packagesId, getQr }, { responseType: "blob" });
