import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CallSplit from "@material-ui/icons/CallSplit";
import Check from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Home from "@material-ui/icons/Home";
import MergeType from "@material-ui/icons/MergeType";
import Loading from "components/Loading";
import { Link as RouterLink } from "components/router";
import { palette } from "config/theme";
import { isAfter, isToday, startOfDay } from "date-fns";
import { useRef, useState } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import usePermissions from "shared/hooks/usePermissions";
import { removePackageFromTour } from "shared/network/package.api";
import { mergeTour, splitTour } from "shared/network/tour.api";
import { Address } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import PackageAddressModal from "./PackageAddressModal";
import { Tour } from "./TourSortingModal";

type Props = {
  tour: Tour;
  address: Address;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  wrongOrderIds: number[];
  refetch: () => void;
  cards: Tour[];
  date: Date;
  mergeable?: number;
  orderChanged: boolean;
};

const useStyles = makeStyles(
  () => ({
    drag: {
      cursor: "grab",
      "& :active": {
        cursor: "grabbing",
      },
    },
  }),
  { name: "PackageListItem" },
);

const TourSortingListItem = ({
  tour,
  address,
  index,
  cards,
  moveCard,
  wrongOrderIds,
  refetch,
  date,
  mergeable,
  orderChanged,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: () => {
      return { tour };
    },
    hover(item: { id: number; index: number }, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id: tour?.id, index };
    },
    canDrag: () => dragEnabled,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  async function onPackageDelete(pack: any) {
    let selectedTours = cards.filter(card =>
      card.relTourPackages.find(value => value.packages.id === pack.packages.id),
    );
    setLoading(true);
    try {
      selectedTours.length === 2 &&
        selectedTours[0] &&
        selectedTours[1] &&
        (await removePackageFromTour({
          tourId1: selectedTours[0].id,
          tourId2: selectedTours[1].id,
          packagesId: pack.packages.id,
        }));
      refetch();
    } catch (err) {}
    setLoading(false);
  }

  async function onSplit(data: { packagesId: number; tourId: number }) {
    setLoading(true);
    try {
      await splitTour(data);
      refetch();
    } catch (err) {}
    setLoading(false);
  }
  async function onMerge(data: { firstTourId: number; secondTourId: number }) {
    setLoading(true);
    try {
      await mergeTour(data);
      refetch();
    } catch (err) {}
    setLoading(false);
  }
  const { isAdmin, isCustomerService, checkPermissions } = usePermissions();
  const dragEnabled =
    ((isAfter(startOfDay(date), new Date()) || isToday(date)) && isAdmin) ||
    ((isAfter(startOfDay(date), new Date()) || isToday(date)) &&
      checkPermissions({
        requestedPermissions: ["SHIPORGANIZER"],
      }));

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          ref={ref}
          style={{ opacity }}
          data-handler-id={handlerId}
          className={dragEnabled ? classes.drag : undefined}
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="center">
              {dragEnabled && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 12,
                  }}
                >
                  <DragIndicatorIcon color="disabled" />
                </div>
              )}
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {`${tour.tourOrder}. ${displayAddress(address)}`}
              </Typography>
              {tour.isFinal && (
                <Tooltip title={t("tour.finalizeTooltip").toString()}>
                  <Check style={{ margin: "0 12px", color: palette.green }} />
                </Tooltip>
              )}
            </Box>
            {!!mergeable && (
              <Tooltip
                title={
                  dragEnabled
                    ? orderChanged
                      ? t("tour.tourSave").toString()
                      : t("tour.merge").toString()
                    : t("tour.pastModify").toString()
                }
              >
                <Box>
                  <IconButton
                    size="small"
                    disabled={!dragEnabled || orderChanged}
                    onClick={() => onMerge({ firstTourId: mergeable, secondTourId: tour.id })}
                  >
                    <MergeType style={{ transform: "rotate(90deg) scaleX(-1)" }} />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </Box>
          {tour?.relTourPackages?.map(pack => (
            <Box key={pack.id}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                marginLeft="36px"
              >
                <Box display="flex" alignItems="center" gridGap={12}>
                  {pack.packagesType === "UP" ? (
                    <Tooltip title={t("tour.packageUp").toString()}>
                      <ArrowUpward fontSize="small" />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("tour.packageDown").toString()}>
                      <ArrowDownward fontSize="small" />
                    </Tooltip>
                  )}
                  <Box>
                    <Link
                      component={RouterLink}
                      to={`/package/${pack.packages.id}`}
                      underline="hover"
                    >
                      <Tooltip title={t("common:button.details").toString()}>
                        <Typography
                          variant="body1"
                          style={{
                            color: wrongOrderIds.includes(pack.packages.id) ? "red" : "black",
                          }}
                        >
                          {pack.packages.trackingNumber}
                        </Typography>
                      </Tooltip>
                    </Link>
                  </Box>
                  {pack.packages.actualStatus.status === "TAKEN_FROM_STOCK" ? (
                    <FontAwesomeIcon icon={faCircleXmark} size="xl" color={palette.brickRed} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleCheck} size="xl" color={palette.green} />
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  {tour?.relTourPackages?.length > 1 &&
                    (isAdmin ||
                      checkPermissions({
                        requestedPermissions: ["SHIPORGANIZER"],
                      })) && (
                      <Tooltip
                        title={
                          dragEnabled
                            ? orderChanged
                              ? t("tour.tourSave").toString()
                              : t("tour.split").toString()
                            : t("tour.pastModify").toString()
                        }
                      >
                        <Box>
                          <IconButton
                            size="small"
                            disabled={!dragEnabled || orderChanged}
                            style={{ marginRight: 6 }}
                            onClick={() =>
                              onSplit({
                                tourId: tour.id,
                                packagesId: pack.packages.id,
                              })
                            }
                          >
                            <CallSplit style={{ transform: "rotate(180deg)" }} />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    )}
                  {pack.packagesType === "DOWN" &&
                    (isAdmin ||
                      isCustomerService ||
                      checkPermissions({
                        requestedPermissions: ["SHIPORGANIZER"],
                      })) && (
                      <>
                        <Tooltip
                          title={
                            dragEnabled
                              ? orderChanged
                                ? t("tour.tourSave").toString()
                                : t("tour.addressModify").toString()
                              : t("tour.pastModify").toString()
                          }
                        >
                          <Box>
                            <IconButton
                              size="small"
                              disabled={!dragEnabled || orderChanged}
                              style={{ marginRight: 6 }}
                              onClick={() => setIsOpen(true)}
                            >
                              <Home />
                            </IconButton>
                          </Box>
                        </Tooltip>
                        <PackageAddressModal
                          open={isOpen}
                          tour={tour}
                          pack={pack.packages}
                          setOpen={setIsOpen}
                          refetch={refetch}
                        />
                      </>
                    )}
                  {(isAdmin ||
                    checkPermissions({
                      requestedPermissions: ["SHIPORGANIZER"],
                    })) && (
                    <Tooltip
                      title={
                        dragEnabled
                          ? orderChanged
                            ? t("tour.tourSave").toString()
                            : t("tour.packageDelete").toString()
                          : t("tour.pastModify").toString()
                      }
                    >
                      <Box>
                        <IconButton
                          disabled={!dragEnabled || orderChanged}
                          size="small"
                          onClick={() => onPackageDelete(pack)}
                        >
                          <DeleteIcon
                            style={{
                              color: dragEnabled && !orderChanged ? palette.brickRed : undefined,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              {pack?.packages?.isPUP && pack?.packagesType === "DOWN" && (
                <Box marginLeft="36px">
                  <Typography variant="body1" style={{ fontSize: 12 }}>
                    <span style={{ fontWeight: "bold", marginRight: 4 }}>
                      {pack?.packages?.customerName},
                    </span>
                    {displayAddress(pack?.packages?.alternateAddress)}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default TourSortingListItem;
